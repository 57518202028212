import React from "react";
import { graphql } from "gatsby";

import CommercialPropertySEO from "../../components/services/commercial-property-finance/commercialPropertySEO";
import BlogsSection from "../../components/shared/blogsSection";
import { Query } from "@graphql-types";
import TabsLink from "@components/shared/sub/tabsLink";
import { Ctas, TextButtons, Header, IframeEmbed } from "@global";
import TestimonialsCarousel from "@components/shared/testimonials";

interface Props {
  data: Query;
}

const CommercialProperty = ({ data }: Props) => {
  if (data?.sanityCommercialPropertyPage == null) return null;
  const {
    ctas,
    textButtons,
    title,
    header,
    iframeEmbed,
    topTextButtons,
  } = data.sanityCommercialPropertyPage;
  const shared = data.sanitySharedSection;
  return (
    <div className="subpage-container">
      <CommercialPropertySEO />
      <Header title={title} headerData={header} />

      {shared?.servicesTabs && (
        <TabsLink
          tabs={shared?.servicesTabs}
          pageColor={header?.headerColor}
          slug="/services/commercial-property-finance/"
        />
      )}
      {topTextButtons && <TextButtons data={topTextButtons} />}
      {ctas && <Ctas data={ctas} />}
      {iframeEmbed && <IframeEmbed data={iframeEmbed} />}
      {textButtons && <TextButtons data={textButtons} />}
      <TestimonialsCarousel color="red" />
      <BlogsSection title="Related Blogs" category="Commercial Property Finance" />
    </div>
  );
};

export default CommercialProperty;

export const query = graphql`
  query {
    sanityCommercialPropertyPage {
      title
      header {
        ...sanityHeader
      }
      topTextButtons {
        ...sanityTextButton
      }
      iframeEmbed {
        ...sanityIframeEmbed
      }
      textButtons {
        ...sanityTextButton
      }
      ctas {
        ...sanityCtas
      }
    }
    sanitySharedSection {
      servicesTabs {
        serviceTab {
          _key
          linkText
          url
        }
      }
    }
  }
`;
