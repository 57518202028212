import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import SEO, { seoDataFormat } from "../../shared/seo"

const CommercialPropertySEO = () => {
  const {
    sanityCommercialPropertyPage: { seo },
  } = useStaticQuery(graphql`
    query {
      sanityCommercialPropertyPage {
        seo {
          ogImage {
            asset {
              url
            }
          }
          pageDescription
          pageKeyWords
          pageTitle
          slug {
            current
          }
        }
      }
    }
  `)

  const seoData = seoDataFormat(seo)

  return <SEO {...seoData} />
}

export default CommercialPropertySEO
